import React, { useState } from "react"
import { toast } from "react-toastify"
import { Button } from "../../.."
import { AddDigitalCards, DeliveryMethods } from "../../../../Code/Data"
import { getCustomSectionHtml } from "../../../../Code/Utilities"
import { usePurchaseData } from "../../../../Context/purchase"
import { useUserData } from "../../../../Context/user"
import styles from "./styles.module.scss"

function Step6({ onBackToEdit }) {
  const [loading, setLoading] = useState()
  // const [previewHtml, setPreviewHtml] = useState()
  const { digitalCardData } = usePurchaseData()
  const { loggedInUser } = useUserData()
  const {
    EcardFromName,
    CompanyName,
    CorporateLogoUrl,
    HideAmountAllOrders,
  } = loggedInUser || {}

  const {
    index,
    card,
    deliveryMethod,
    customImage,
    amount,
    from,
    recipients,
    greeting,
    message,
    numOfPrintCards,
    sendDate,
    customText,
    brandEcard
  } = digitalCardData
  const customSection = getCustomSectionHtml(customText, card.CustomSectionDefaultHtml)
  const recipientName = (recipients.length > 2 ? '[Recipient Name]' : recipients[0]?.FullName)
  const fromName = EcardFromName || CompanyName
  const donationAmount = `$${Number(amount).toFixed(2)}`



  const handleSubmit = async () => {
    const payload = {
      CardId: card?.CardId,
      CustomCardSectionHtml: customSection,
      Denomination: amount,
      SmsFrom: from,
      CardMessage: message,
      CardGreeting: greeting,
      DigitalCardDeliveryType: deliveryMethod,
      Recipients: recipients,
      NumberOfPrintCards: numOfPrintCards,
      CustomCardImageURL: customImage,
      BrandCard: brandEcard
    }

    if (sendDate) {
      payload.SendDateUtc = sendDate
    }

    setLoading(true)
    const { Succeeded, ErrorMessage } = await AddDigitalCards(payload, index)
    setLoading(false)

    if (Succeeded) {
      window.location.href = "/Purchase/ShoppingCart"
    } else {
      toast.error(ErrorMessage)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>Preview Your Gift Card</div>
      <div className={styles.content}>
        <div className={styles.detailContent}>
          <div className={styles.greeting}>
            {greeting}
            {deliveryMethod !== DeliveryMethods.Print && (
              <div>{recipientName}</div>
            )}
          </div>
          {CorporateLogoUrl && brandEcard && (
            <img
              className={styles.corpLogo}
              src={CorporateLogoUrl}
              alt="Corporate Logo"
            />
          )}
          <div className={styles.detailLabel}>
            <span>{fromName || "[Your name will appear here]"}</span> would like
            to
            <br />
            donate on your behalf to a charity
            <br />
            of your choosing.
          </div>
        </div>
        <div className={styles.cardWrapper}>
          <div className={styles.imgWrapper}>
            {customImage && !customSection ? (
              <img src={customImage} alt="Custom Card" />
            ) : (
              <img
                src={`//res.cloudinary.com/charity-choice/eCards/${customSection
                  ? "CustomizableCards/"
                  : ""}${card?.CardId}.jpg`}
                alt="Season's Greetings"
              />
            )}
            {customSection && (
              <div
                className={styles.cutomSection}
                dangerouslySetInnerHTML={{
                  __html: `<p class="__customSection">${customSection}</p>`,
                }}></div>
            )}
          </div>
          <div className={styles.cardContent} dangerouslySetInnerHTML={{
            __html: message.replace(/\<.+?\>/gi, '').replace(/\n/gi, '<br />')
          }}></div>
          {!HideAmountAllOrders && (
            <div className={styles.cardFooter}>
              <div className={styles.donationAmount}>{donationAmount}</div>
              <div className={styles.donationLabel}>Donation Amount</div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.typeMethod}>
        <Button outline color="red" onClick={onBackToEdit}>
          Let's make changes
        </Button>
        <Button loading={loading} onClick={handleSubmit}>
          I like it, let's continue
        </Button>
      </div>
    </div>
  )
}

export default Step6
